import * as React from 'react'
import { Helmet } from 'react-helmet'

import '../styles/main.sass'
import logoImage from '../images/logo.png'

const App = () => {
	return (
		<main>
			<Helmet
				htmlAttributes={{
					lang: 'nl',
				}}
			>
				<title>(404) Marianne Ottevanger - Zangeres en pianiste</title>
				<meta
					name='keywords'
					content='marianne ottevanger, zangeres, pianiste, epe, muziek'
				/>
				<meta
					name='description'
					content='Zangeres en pianiste. Van jongs af aan is muziek mijn passie. Ik wil met mijn muziek graag iets toevoegen aan bijzondere momenten in jouw leven.'
				/>
				<link rel='canonical' href='https://www.marianneottevanger.com/' />
				{/*<meta property='og:image' content={landing} />*/}
			</Helmet>
			<div className={'block block-404'}>
				<div className={'block-content'}>
					<h2>Deze pagina bestaat niet</h2>
					<p>
						Waar ben je naar op zoek? Bekijk{' '}
						<a href='/' className='with-icon'>
							de homepagina
						</a>{' '}
						of{' '}
						<a href='/#contact' className='with-underline'>
							neem contact met me op
						</a>{' '}
						als je een vraag hebt.
					</p>
					<div className={'logo-container'}>
						<div className={'logo-icon'}>
							<img alt={'Logo van Marianne Ottevanger'} src={logoImage} />
						</div>
						<div className={'logo-text'}>
							<h1>Marianne Ottevanger</h1>
							<h3>Zangeres en pianiste</h3>
						</div>
					</div>
				</div>
			</div>
		</main>
	)
}

export default App
